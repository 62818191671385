<template>
  <div
    class="csn-masm"
    :class="{ 'csn-masm-displayed': SHOWS_MY_ACCOUNT_SIDE_MENU }"
  >
    <div class="csn-masm-header">
      <button @click="goBackToMainMenu" class="csn-masm-arrow-back">
        <SlideArrow />
      </button>
      <span class="csn-masm-title">{{ t('my_account') }}</span>
      <button @click="closeMenu" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>
    <div class="csn-masm-list">
      <UserAccountMenuList @toggle="closeMenu" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  MY_ACCOUNT_SIDE_MENU,
  SHOWS_MY_ACCOUNT_SIDE_MENU,
  TOGGLE_MY_ACCOUNT_SIDE_MENU,
  TOGGLE_MAIN_MENU,
  Module,
} from '@/constants'
export default {
  name: MY_ACCOUNT_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    UserAccountMenuList: () =>
      import('@/views/User/components/UserAccountMenuList'),
  },
  computed: {
    ...mapState(Module.MAIN, [SHOWS_MY_ACCOUNT_SIDE_MENU]),
    t() {
      return this.$createComponentTranslator(MY_ACCOUNT_SIDE_MENU)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MY_ACCOUNT_SIDE_MENU,
      TOGGLE_MAIN_MENU,
    ]),
    closeMenu() {
      this.TOGGLE_MY_ACCOUNT_SIDE_MENU(false)
    },
    async goBackToMainMenu() {
      await this.TOGGLE_MY_ACCOUNT_SIDE_MENU(false)
      this.TOGGLE_MAIN_MENU()
    },
  },
}
</script>
